import { NfeV001CompModel } from '../../views/pages/04-documento/nota-fiscal/components/nfe/models/nfe-v001-comp.model';
import { InputMasks } from '../models/input-masks.model';

export class Tools {

	static isJsonString(str: string) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	static extend(obj, src) {
		Object.keys(src).forEach((key) => { obj[key] = src[key]; });
		return obj;
	}

	static removeAllWhiteSpaces(str: string) {
		return str.replace(/\s/g, '');
	}

	static getBase64ImageDimensions(image: any) {
		return new Promise((resolve, reject) => {
			let img = new Image();
			img.onload = function () {
				resolve({ width: img.width, height: img.height });
			};

			img.src = image;
		});
	}

	static convertArrayToObject = (array, key) => {
		const initialValue = {};
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item,
			};
		}, initialValue);
	}

	static brazilianCurrency(value: number, noSymbol?: boolean) {
		let formated = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) + '';

		// formated.replace('.', ',');

		formated = formated.replace('R$', '');

		if (noSymbol) {
			// formated = formated.replace('R$', '');
			// console.log('formated', formated);
		}

		return formated;
	}

	static decimalFormats(value, casasDecimais, separadorDecimais, separadorMilhares) {
		casasDecimais = isNaN(casasDecimais = Math.abs(casasDecimais)) ? 2 : casasDecimais,
			separadorDecimais = typeof separadorDecimais === "undefined" ? "," : separadorDecimais;
		separadorMilhares = typeof separadorMilhares === "undefined" ? "." : separadorMilhares;
		var sign = value < 0 ? "-" : "";
		var i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(casasDecimais)));
		var j = (j = i.length) > 3 ? j % 3 : 0;

		return sign +
			(j ? i.substring(0, j) + separadorMilhares : "") +
			i.substring(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + separadorMilhares) +
			(casasDecimais ? separadorDecimais + Math.abs(value - Number(i)).toFixed(casasDecimais).slice(2) : "");
	}

	static toAngularDatePickerFormat(date: Date) {
		return {
			singleDate: {
				date: {
					year: date.getFullYear(),
					month: date.getMonth() + 1,
					day: date.getDate()
				},
				jsDate: date
			}
		};
	}

	static toUTF8Array(str): any[] {
		str = String(str.result).replace("data:", "").replace(/^.+,/, "");
		const utf8 = [];
		for (let i = 0; i < str.length; i++) {
			let charcode = str.charCodeAt(i);
			if (charcode < 0x80) {
				utf8.push(charcode);
			} else if (charcode < 0x800) {
				utf8.push(0xc0 | (charcode >> 6),
					0x80 | (charcode & 0x3f));
			}
			else if (charcode < 0xd800 || charcode >= 0xe000) {
				utf8.push(0xe0 | (charcode >> 12),
					0x80 | ((charcode >> 6) & 0x3f),
					0x80 | (charcode & 0x3f));
			}
			// surrogate pair
			else {
				i++;
				// UTF-16 encodes 0x10000-0x10FFFF by
				// subtracting 0x10000 and splitting the
				// 20 bits of 0x0-0xFFFFF into two halves
				charcode = 0x10000 + (((charcode & 0x3ff) << 10)
					| (str.charCodeAt(i) & 0x3ff));
				utf8.push(0xf0 | (charcode >> 18),
					0x80 | ((charcode >> 12) & 0x3f),
					0x80 | ((charcode >> 6) & 0x3f),
					0x80 | (charcode & 0x3f));
			}
		}
		return utf8;
	}

	static base64ToArrayBuffer(base64: string): Uint8Array {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);

		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}

		return bytes;
	}

	static fileReaderToBase64(str): any {
		return String(str).replace("data:", "").replace(/^.+,/, "");
	}

	static randomColor(): string {
		return '#' + Math.floor(Math.random() * 16777215).toString(16);
	}

	static mascaraDinamicaCPFCNPJ(str: string): string {
		if (str.length > 0) {
			str = str.replace(/\D/g, '');

			if (str.length <= 11) {
				str = str.replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4");
			}
			else {
				if (str.length > 14) {

					str = str.substring(0, 14);
				}

				str = str.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
			}
		}

		return str;
	}

	static limparMascara(str: string): string {
		return str.replace(/\./g, '').replace('/', '').replace('-', '');
	}

	static mascaraDinamicaTelefone(str: string): string {
		if (str.length > 0) {
			str = str.replace(/\D/g, "");

			str = str.replace(/^0/, "");

			if (str.length > 10) {
				str = str.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1)$2-$3");
			} else if (str.length > 5) {
				str = str.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1)$2-$3");
			} else if (str.length > 2) {
				str = str.replace(/^(\d\d)(\d{0,5})/, "($1)$2");
			} else {
				str = str.replace(/^(\d*)/, "($1");
			}
		}

		return str;
	}

	static manipulaListaIDXML(array: any[]): string {
		let listaIDXML: string = '';

		if (array && array.length > 0) {
			array.forEach(element => {
				listaIDXML = listaIDXML + '<ID>' + String(element) + '</ID>';
			});
		}
		else {
			listaIDXML = null;
		}

		return listaIDXML;
	}

	static manipulaListaIDString(array: any[], separador: string): string {
		let listaIDString: string = '';

		if (array && array.length > 0) {
			for (let index = 0; index < array.length; index++) {
				if (array[index]) {
					if (index === 0) {
						listaIDString = listaIDString + String(array[index])
					}
					else {
						listaIDString = listaIDString + separador + String(array[index])
					}
				}
			}
		}
		else {
			listaIDString = null;
		}

		listaIDString = listaIDString.replace(',,',',');

		return listaIDString;
	}

	static deserializeResponse(response: any): NfeV001CompModel {
		let retornoSefazModel = new NfeV001CompModel();

		retornoSefazModel.cStat = response.cStat;
		retornoSefazModel.cStat = response.cStat;
		retornoSefazModel.cUF = Number(response.cUF);
		retornoSefazModel.dhRecbto = response.dhRecbto;
		retornoSefazModel.tMed = response.tMed;
		retornoSefazModel.byteArray = response.byteArray;
		if (response.tpAmb !== '1') {
			retornoSefazModel.tpAmb = 0;
		}
		else {
			retornoSefazModel.tpAmb = Number(response.tpAmb);
		}
		retornoSefazModel.verAplic = response.verAplic;
		retornoSefazModel.versao = response.versao;
		retornoSefazModel.xMotivo = response.xMotivo;
		if (response.protNFe) {
			if (response.protNFe.infProt) {
				retornoSefazModel.cStat = response.protNFe.infProt.cStat;
				retornoSefazModel.chNFe = response.protNFe.infProt.chNFe;
				retornoSefazModel.dhRecbto = response.protNFe.infProt.dhRecbto;
				retornoSefazModel.digVal = response.protNFe.infProt.digVal;
				retornoSefazModel.nProt = response.protNFe.infProt.nProt;
				retornoSefazModel.xMotivo = response.protNFe.infProt.xMotivo;
			}
		}

		if (response.procEventoNFe) {
			response.procEventoNFe.forEach(element => {
				if (element) {
					if (element.evento) {
						if (element.evento.infEvento) {
							if (element.evento.infEvento.tpEvento === '110110') {
								retornoSefazModel.cOrgaoCCe = Number(element.evento.infEvento.cOrgao);
								if (element.evento.infEvento.tpAmb !== '1') {
									retornoSefazModel.tpAmbCCe = 0;
								}
								else {
									retornoSefazModel.tpAmbCCe = Number(element.evento.infEvento.tpAmb);
								}
								retornoSefazModel.CNPJCCe = element.evento.infEvento.CNPJ;
								retornoSefazModel.dhEventoCCe = element.evento.infEvento.dhEvento;
								retornoSefazModel.verEventoCCe = element.evento.infEvento.verEvento;
								retornoSefazModel.descEventoCCe = element.evento.infEvento.detEvento.descEvento;
								retornoSefazModel.xCorrecaoCCe = element.evento.infEvento.detEvento.xCorrecao;
								retornoSefazModel.nProtCCe = element.evento.infEvento.detEvento.nProt;
							}

							if (element.evento.infEvento.tpEvento === '110111') {
								retornoSefazModel.cOrgaoCan = Number(element.evento.infEvento.cOrgao);
								if (element.evento.infEvento.tpAmb !== '1') {
									retornoSefazModel.tpAmbCan = 0;
								}
								else {
									retornoSefazModel.tpAmbCan = Number(element.evento.infEvento.tpAmb);
								}
								retornoSefazModel.CNPJCan = element.evento.infEvento.CNPJ;
								retornoSefazModel.dhEventoCan = element.evento.infEvento.dhEvento;
								retornoSefazModel.verEventoCan = element.evento.infEvento.verEvento;
								retornoSefazModel.descEventoCan = element.evento.infEvento.detEvento.descEvento;
								retornoSefazModel.xJustCan = element.evento.infEvento.detEvento.xJust;
								retornoSefazModel.nProtCan = element.evento.infEvento.detEvento.nProt;
							}

							if (['210200', '210210', '210220', '210240'].indexOf(element.evento.infEvento.tpEvento) > -1) {
								retornoSefazModel.cOrgaoMani = Number(element.evento.infEvento.cOrgao);
								if (element.evento.infEvento.tpAmb !== '1') {
									retornoSefazModel.tpAmbMani = 0;
								}
								else {
									retornoSefazModel.tpAmbMani = Number(element.evento.infEvento.tpAmb);
								}
								retornoSefazModel.CNPJMani = element.evento.infEvento.CNPJ;
								retornoSefazModel.dhEventoMani = element.evento.infEvento.dhEvento;
								retornoSefazModel.verEventoMani = element.evento.infEvento.verEvento;
								retornoSefazModel.descEventoMani = element.evento.infEvento.detEvento.descEvento;
								retornoSefazModel.xJustMani = element.evento.infEvento.detEvento.xJust;
								retornoSefazModel.nProtMani = element.evento.infEvento.detEvento.nProt;
							}
						}
					}

					if (element.retEvento) {
						if (element.retEvento.infEvento) {
							if (element.retEvento.infEvento.tpEvento === '110110') {
								retornoSefazModel.cOrgaoCCe = Number(element.retEvento.infEvento.cOrgao);
								if (element.retEvento.infEvento.tpAmb !== '1') {
									retornoSefazModel.tpAmbCCe = 0;
								}
								else {
									retornoSefazModel.tpAmbCCe = Number(element.retEvento.infEvento.tpAmb);
								}
								retornoSefazModel.chNFeCCe = element.retEvento.infEvento.chNFe;
								retornoSefazModel.tpEventoCCe = element.retEvento.infEvento.tpEvento;
								retornoSefazModel.nSeqEventoCCe = element.retEvento.infEvento.nSeqEvento;
								retornoSefazModel.cStatCCe = element.retEvento.infEvento.cStat;
								retornoSefazModel.dhRegEventoCCe = element.retEvento.infEvento.dhRegEvento;
								retornoSefazModel.CNPJDestCCe = element.retEvento.infEvento.CNPJDest;
								retornoSefazModel.emailDestCCe = element.retEvento.infEvento.emailDest;
								retornoSefazModel.xMotivoCCe = element.retEvento.infEvento.xMotivo;
							}

							if (element.retEvento.infEvento.tpEvento === '110111') {
								retornoSefazModel.cOrgaoCan = Number(element.retEvento.infEvento.cOrgao);
								if (element.retEvento.infEvento.tpAmb !== '1') {
									retornoSefazModel.tpAmbCan = 0;
								}
								else {
									retornoSefazModel.tpAmbCan = Number(element.retEvento.infEvento.tpAmb);
								}
								retornoSefazModel.chNFeCan = element.retEvento.infEvento.chNFe;
								retornoSefazModel.tpEventoCan = element.retEvento.infEvento.tpEvento;
								retornoSefazModel.nSeqEventoCan = element.retEvento.infEvento.nSeqEvento;
								retornoSefazModel.cStatCan = element.retEvento.infEvento.cStat;
								retornoSefazModel.dhRegEventoCan = element.retEvento.infEvento.dhRegEvento;
								retornoSefazModel.CNPJDestCan = element.retEvento.infEvento.CNPJDest;
								retornoSefazModel.emailDestCan = element.retEvento.infEvento.emailDest;
								retornoSefazModel.xMotivoCan = element.retEvento.infEvento.xMotivo;
							}

							if (['210200', '210210', '210220', '210240'].indexOf(element.retEvento.infEvento.tpEvento) > -1) {
								retornoSefazModel.cOrgaoMani = Number(element.retEvento.infEvento.cOrgao);
								if (element.retEvento.infEvento.tpAmb !== '1') {
									retornoSefazModel.tpAmbMani = 0;
								}
								else {
									retornoSefazModel.tpAmbMani = Number(element.retEvento.infEvento.tpAmb);
								}
								retornoSefazModel.chNFeMani = element.retEvento.infEvento.chNFe;
								retornoSefazModel.tpEventoMani = element.retEvento.infEvento.tpEvento;
								retornoSefazModel.nSeqEventoMani = element.retEvento.infEvento.nSeqEvento;
								retornoSefazModel.cStatMani = element.retEvento.infEvento.cStat;
								retornoSefazModel.dhRegEventoMani = element.retEvento.infEvento.dhRegEvento;
								retornoSefazModel.CNPJDestMani = element.retEvento.infEvento.CNPJDest;
								retornoSefazModel.emailDestMani = element.retEvento.infEvento.emailDest;
								retornoSefazModel.xMotivoMani = element.retEvento.infEvento.xMotivo;
							}
						}
					}
				}
			});
		} else {
			if (response.evento) {
				if (response.evento.infEvento) {
					if (response.evento.infEvento.tpEvento === '110110') {
						retornoSefazModel.cOrgaoCCe = Number(response.evento.infEvento.cOrgao);
						if (response.evento.infEvento.tpAmb !== '1') {
							retornoSefazModel.tpAmbCCe = 0;
						}
						else {
							retornoSefazModel.tpAmbCCe = Number(response.evento.infEvento.tpAmb);
						}
						retornoSefazModel.CNPJCCe = response.evento.infEvento.CNPJ;
						retornoSefazModel.dhEventoCCe = response.evento.infEvento.dhEvento;
						retornoSefazModel.verEventoCCe = response.evento.infEvento.verEvento;
						retornoSefazModel.descEventoCCe = response.evento.infEvento.detEvento.descEvento;
						retornoSefazModel.xCorrecaoCCe = response.evento.infEvento.detEvento.xCorrecao;
						retornoSefazModel.nProtCCe = response.evento.infEvento.detEvento.nProt;
					}

					if (response.evento.infEvento.tpEvento === '110111') {
						retornoSefazModel.cOrgaoCan = Number(response.evento.infEvento.cOrgao);
						if (response.evento.infEvento.tpAmb !== '1') {
							retornoSefazModel.tpAmbCan = 0;
						}
						else {
							retornoSefazModel.tpAmbCan = Number(response.evento.infEvento.tpAmb);
						}
						retornoSefazModel.CNPJCan = response.evento.infEvento.CNPJ;
						retornoSefazModel.dhEventoCan = response.evento.infEvento.dhEvento;
						retornoSefazModel.verEventoCan = response.evento.infEvento.verEvento;
						retornoSefazModel.descEventoCan = response.evento.infEvento.detEvento.descEvento;
						retornoSefazModel.xJustCan = response.evento.infEvento.detEvento.xJust;
						retornoSefazModel.nProtCan = response.evento.infEvento.detEvento.nProt;
					}

					if (['210200', '210210', '210220', '210240'].indexOf(response.evento.infEvento.tpEvento) > -1) {
						retornoSefazModel.cOrgaoMani = Number(response.evento.infEvento.cOrgao);
						if (response.evento.infEvento.tpAmb !== '1') {
							retornoSefazModel.tpAmbMani = 0;
						}
						else {
							retornoSefazModel.tpAmbMani = Number(response.evento.infEvento.tpAmb);
						}
						retornoSefazModel.CNPJMani = response.evento.infEvento.CNPJ;
						retornoSefazModel.dhEventoMani = response.evento.infEvento.dhEvento;
						retornoSefazModel.verEventoMani = response.evento.infEvento.verEvento;
						retornoSefazModel.descEventoMani = response.evento.infEvento.detEvento.descEvento;
						retornoSefazModel.xJustMani = response.evento.infEvento.detEvento.xJust;
						retornoSefazModel.nProtMani = response.evento.infEvento.detEvento.nProt;
					}
				}
			}

			if (response.retEvento) {
				if (response.retEvento.infEvento) {
					if (response.retEvento.infEvento.tpEvento === '110110') {
						retornoSefazModel.cOrgaoCCe = Number(response.retEvento.infEvento.cOrgao);
						if (response.retEvento.infEvento.tpAmb !== '1') {
							retornoSefazModel.tpAmbCCe = 0;
						}
						else {
							retornoSefazModel.tpAmbCCe = Number(response.retEvento.infEvento.tpAmb);
						}
						retornoSefazModel.chNFeCCe = response.retEvento.infEvento.chNFe;
						retornoSefazModel.tpEventoCCe = response.retEvento.infEvento.tpEvento;
						retornoSefazModel.nSeqEventoCCe = response.retEvento.infEvento.nSeqEvento;
						retornoSefazModel.cStatCCe = response.retEvento.infEvento.cStat;
						retornoSefazModel.dhRegEventoCCe = response.retEvento.infEvento.dhRegEvento;
						retornoSefazModel.CNPJDestCCe = response.retEvento.infEvento.CNPJDest;
						retornoSefazModel.emailDestCCe = response.retEvento.infEvento.emailDest;
						retornoSefazModel.xMotivoCCe = response.retEvento.infEvento.xMotivo;
					}

					if (response.retEvento.infEvento.tpEvento === '110111') {
						retornoSefazModel.cOrgaoCan = Number(response.retEvento.infEvento.cOrgao);
						if (response.retEvento.infEvento.tpAmb !== '1') {
							retornoSefazModel.tpAmbCan = 0;
						}
						else {
							retornoSefazModel.tpAmbCan = Number(response.retEvento.infEvento.tpAmb);
						}
						retornoSefazModel.chNFeCan = response.retEvento.infEvento.chNFe;
						retornoSefazModel.tpEventoCan = response.retEvento.infEvento.tpEvento;
						retornoSefazModel.nSeqEventoCan = response.retEvento.infEvento.nSeqEvento;
						retornoSefazModel.cStatCan = response.retEvento.infEvento.cStat;
						retornoSefazModel.dhRegEventoCan = response.retEvento.infEvento.dhRegEvento;
						retornoSefazModel.CNPJDestCan = response.retEvento.infEvento.CNPJDest;
						retornoSefazModel.emailDestCan = response.retEvento.infEvento.emailDest;
						retornoSefazModel.xMotivoCan = response.retEvento.infEvento.xMotivo;
					}

					if (['210200', '210210', '210220', '210240'].indexOf(response.retEvento.infEvento.tpEvento) > -1) {
						retornoSefazModel.cOrgaoMani = Number(response.retEvento.infEvento.cOrgao);
						if (response.retEvento.infEvento.tpAmb !== '1') {
							retornoSefazModel.tpAmbMani = 0;
						}
						else {
							retornoSefazModel.tpAmbMani = Number(response.retEvento.infEvento.tpAmb);
						}
						retornoSefazModel.chNFeMani = response.retEvento.infEvento.chNFe;
						retornoSefazModel.tpEventoMani = response.retEvento.infEvento.tpEvento;
						retornoSefazModel.nSeqEventoMani = response.retEvento.infEvento.nSeqEvento;
						retornoSefazModel.cStatMani = response.retEvento.infEvento.cStat;
						retornoSefazModel.dhRegEventoMani = response.retEvento.infEvento.dhRegEvento;
						retornoSefazModel.CNPJDestMani = response.retEvento.infEvento.CNPJDest;
						retornoSefazModel.emailDestMani = response.retEvento.infEvento.emailDest;
						retornoSefazModel.xMotivoMani = response.retEvento.infEvento.xMotivo;
					}
				}
			}
		}

		return retornoSefazModel;
	}

	static gerarDictionaryNumberString(keyNumber: number, valueString: string): { [key: number]: string; } {
		const dictionaryXMLByte: { [key: number]: string; } = {};
		dictionaryXMLByte[keyNumber] = valueString;

		return dictionaryXMLByte;
	}

	static retornaArrayStringGet(nomeParametro: string, nomeFilter: string, array: number[]) {
		let query: string = '?' + nomeFilter + '.' + nomeParametro + '=' + array[0];
		for (let index = 1; index < array.length; index++) {
			const element = array[index];
			query += '&' + nomeFilter + '.' + nomeParametro + '=' + array[index];
		}

		return query;
	}

	static tratarParamentrosQuery(query: any): any {
		for (const item in query) {
			if (query[item] === undefined || query[item] === '' || query[item] === null) {
				delete query[item];
			}
		}

		return query;
	}

	static tratarMensagem(mensagem: string): string {
		if (mensagem && mensagem.length > 0) {
			mensagem = mensagem.replace('<br />', '\n');
			mensagem = mensagem.replace('</br>', '\n');
			mensagem = mensagem.replace('\n\r', '\n');

			if (mensagem === 'Timeout has occurred') {
				mensagem = 'O Tempo de resposta terminou sem retorno!';
			}
		}

		return mensagem;
	}

	static tratarRetornoAPI(retorno: any): string {
		let stringretorno = 'Erro Desconhecido';

		if (retorno.error && typeof retorno.error === 'string' && retorno.error.indexOf('<br />') > -1) {
			return this.tratarMensagem(retorno.error);
		}

		if (retorno.error && typeof retorno.error === 'string') {
			return this.tratarMensagem(retorno.error);
		}

		if (retorno.error !== undefined && typeof retorno.error !== 'string' && retorno.error.Message !== undefined && typeof retorno.error.Message === 'string' && retorno.message !== undefined && typeof retorno.message === 'string' && retorno.message.indexOf('Http failure response') > -1) {
			return this.tratarMensagem(retorno.error.Message);
		}

		if (retorno.error && retorno.error.Message && typeof retorno.error.Message === 'string') {
			return this.tratarMensagem(retorno.error.Message);
		}

		if (retorno.message && typeof retorno.message === 'string') {
			return this.tratarMensagem(retorno.message);
		}

		if (retorno.mensagem && typeof retorno.mensagem === 'string') {
			return this.tratarMensagem(retorno.mensagem);
		}

		return stringretorno;
	}

	static manipularRetornoAPI(retorno: any): string {
		let stringretorno = 'Erro Desconhecido';

		if (retorno.error && typeof retorno.error === 'string' && retorno.error.indexOf('<br />') > -1) {
			return retorno.error;
		}

		if (retorno.error && typeof retorno.error === 'string') {
			return retorno.error;
		}

		if (retorno.error !== undefined && typeof retorno.error !== 'string' && retorno.error.Message !== undefined && typeof retorno.error.Message === 'string' && retorno.message !== undefined && typeof retorno.message === 'string' && retorno.message.indexOf('Http failure response') > -1) {
			return retorno.error;
		}

		if (retorno.error && retorno.error.Message && typeof retorno.error.Message === 'string') {
			return retorno.error.message;
		}

		if (retorno.message && typeof retorno.message === 'string') {
			return retorno.message;
		}

		if (retorno.mensagem && typeof retorno.mensagem === 'string') {
			return retorno.mensagem;
		}

		return stringretorno;
	}

	static gerarCorDinamicamente(): string {
		var r = Math.floor(Math.random() * 255);
		var g = Math.floor(Math.random() * 255);
		var b = Math.floor(Math.random() * 255);
		return "rgb(" + r + "," + g + "," + b + ")";
	}

	static retornaMaskDecimal(casasDecimais?: number): InputMasks {
		let currencyOptions: InputMasks;

		switch (casasDecimais) {
			case 2:
				currencyOptions = InputMasks.currencyOptionsPrecision2;
				break;
			case 3:
				currencyOptions = InputMasks.currencyOptionsPrecision3;
				break;
			case 4:
				currencyOptions = InputMasks.currencyOptionsPrecision4;
				break;
			case 5:
				currencyOptions = InputMasks.currencyOptionsPrecision5;
				break;
			case 6:
				currencyOptions = InputMasks.currencyOptionsPrecision6;
				break;
			case 7:
				currencyOptions = InputMasks.currencyOptionsPrecision7;
				break;
			case 8:
				currencyOptions = InputMasks.currencyOptionsPrecision8;
				break;
			case 9:
				currencyOptions = InputMasks.currencyOptionsPrecision9;
				break;
			case 10:
				currencyOptions = InputMasks.currencyOptionsPrecision10;
				break;
			case 11:
				currencyOptions = InputMasks.currencyOptionsPrecision11;
				break;
			case 12:
				currencyOptions = InputMasks.currencyOptionsPrecision12;
				break;
			default:
				currencyOptions = InputMasks.currencyOptionsPrecision2;
				break;
		}

		return currencyOptions;
	}

	static formataDecimalParaTime(value: number): string {
		if (isNaN(value) || value == null) {
			return '00.00';
		}
	
		// Converte o número para string com 2 casas decimais
		let formatted = Number(value).toFixed(2);
		
		// Garante que sempre tenha dois dígitos antes do ponto decimal
		if (formatted.length < 5) {
			formatted = '0' + formatted;
		}
	
		return formatted;
	}
}
